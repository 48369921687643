<template>
  <div v-if="Object.keys(this.REGISTRY_FORM()).length > 0">
    <el-form
      :model="REGISTRY_FORM()"
      label-width="auto"
      ref="form"
      label-position="left"
      autocomplete="off"
    >
      <el-form-item label="Предприятие">
        <el-input v-model="REGISTRY_FORM().company.title" disabled></el-input>
      </el-form-item>
      <el-form-item label="Комментарий агента">
        <el-input v-model="REGISTRY_FORM().comment_user" disabled></el-input>
      </el-form-item>
      <el-form-item
        size="mini"
        label="Сопроводительные документы"
        prop="document"
      >
        <el-upload
          :disabled="REGISTRY_FORM().addAdmin || REGISTRY_FORM().delivered == 1"
          class="upload-demo"
          ref="upload"
          :limit="1"
          :file-list="fileList"
          accept=" .jpg, .jpeg, .png, .bmp, .docx, .doc, pdf"
          action=""
          :auto-upload="false"
        >
          <el-button slot="trigger" size="small" type="primary"
            >Веберите файл</el-button
          >
          <div class="el-upload__tip" slot="tip">
            jpg/jpeg/png/bmp/doc/docx/pdf
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="Доп. информация">
        <el-form-item>
          <el-input
            type="textarea"
            :disabled="
              REGISTRY_FORM().addAdmin || REGISTRY_FORM().delivered == 1
            "
            placeholder="Введите текст"
            v-model="REGISTRY_FORM().comment_admin"
            maxlength="255"
            show-word-limit
            :autosize="{ minRows: 5 }"
          ></el-input>
        </el-form-item>
      </el-form-item>

      <el-form-item label="Дата">
        <el-date-picker
          v-model="REGISTRY_FORM().date"
          type="date"
          size="mini"
          :disabled="REGISTRY_FORM().addAdmin || REGISTRY_FORM().delivered == 1"
          placeholder="дд.мм.гг"
          format="dd.MM.yyyy"
          value-format="dd.MM.yyyy"
        >
          >
        </el-date-picker>
      </el-form-item>
      <div
        class="el-table el-table--fit el-table--border el-table--scrollable-x el-table--enable-row-hover el-table--enable-row-transition"
        style="margin: 10px 0px 10px 0px;"
      >
        <table
          v-if="REGISTRY_FORM().length != 0 && PLATE_TYPES_LIST().length != 0"
          border="0"
          cellspacing="0"
          class="el-table__header"
          style=" margin: auto;"
          cellpadding
        >
          <thead class="has-gutter">
            <tr>
              <template v-for="(item, index) in PLATE_TYPES_LIST()">
                <th
                  colspan="1"
                  rowspan="1"
                  is-leaf
                  v-if="item.title != 'Тип-2'"
                  :key="index + '-plate'"
                  style="font-size: 10px; padding: 5px;"
                >
                  {{ PLATE_TYPES_LIST()[index].title }}
                </th>
              </template>
              <th
                colspan="1"
                rowspan="1"
                is-leaf
                style="font-size: 10px; padding: 5px;"
              >
                Пленка
              </th>
            </tr>
          </thead>
          <tr>
            <template
              v-for="(item, index) in REGISTRY_FORM().list_requested_materials"
            >
              <td
                :key="item.count + 'count' + index"
                style="font-size: 10px; text-align: center;"
              >
                {{ item.count }}
              </td>
            </template>
          </tr>
        </table>
      </div>
    </el-form>
    <span slot="footer" class="footer-dialog">
      <el-button type="danger" @click="closeModal">
        Отменить
      </el-button>
      <div v-if="REGISTRY_FORM().delivered == 0">
        <el-button type="danger" @click="deleteReg">
          Удалить
        </el-button>
      </div>
      <div v-if="REGISTRY_FORM().delivered == 0">
        <el-button type="success" @click="submit2">
          Обработать
        </el-button>
        <el-button :loading=loadButton type="success" @click.once="submit">
          Добавить на склад
        </el-button>
      </div>
      <div v-else>
        <el-button type="success" @click="closeModal">
          Закрыть
        </el-button>
        <el-button
          type="success"
          @click.once="submit2"
          v-if="REGISTRY_FORM().delivered == 2"
        >
          Обработать
        </el-button>
        <el-button
          :loading=loadButton
          type="success"
          @click.once="submit"
          v-if="REGISTRY_FORM().delivered == 2"
        >
          Добавить на склад
        </el-button>
      </div>
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  props: {
    id: Number
  },
  data() {
    return {
      loadButton: false,
      fileList: [],
      form: {}
    };
  },
  methods: {
    ...mapActions([
      "GET_PLATE_TYPES",
      "GET_REGISTRY",
      "PUT_REGISTRY",
      "GET_REGISTRY_COMPANY_COUNT",
      "DEL_REGISTRY_USER"
    ]),
    ...mapGetters(["PLATE_TYPES_LIST", "REGISTRY_FORM"]),
    closeModal() {
      this.$emit("closeModal");
    },
     submit() {
      if (this.loadButton) {
        return
      }
      this.loadButton = true
      setTimeout(()=> {
        let formData = new FormData();
        if (this.$refs.upload.uploadFiles[0]) {
          formData.append("document_mat", this.$refs.upload.uploadFiles[0].raw);
        }

        formData.append("data", JSON.stringify(this.REGISTRY_FORM()));

        axios.put(`/api/registry`, formData);
        this.$emit("closeModal");
        this.loadButton = false
      }, 1000)

    },

    submit2() {
      let formData = new FormData();
      if (this.$refs.upload.uploadFiles[0]) {
        formData.append("document_mat", this.$refs.upload.uploadFiles[0].raw);
      }

      formData.append("data", JSON.stringify(this.REGISTRY_FORM()));

      axios.put(`/api/registry/check`, formData);
      this.$emit("closeModal");
    },
    deleteReg() {
      this.DEL_REGISTRY_USER(this.REGISTRY_FORM().id);
      this.$emit("closeModal");
      setTimeout(() => {
        this.GET_REGISTRY(this.$route.params.id);
        this.GET_REGISTRY_NEW(this.$route.params.id);
      }, 2000);
    }
  },
  created: function() {}
};
</script>

<style scoped>
.footer-dialog {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-select {
  display: block;
}
</style>
